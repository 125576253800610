<template>
  <div class="app-footer">
    <h3><span class="dot">·</span> Réseaux sociaux</h3>
    <div class="social">
      <a href="https://linktr.ee/s.lina" target="_blank">
        <img src="/linktree.png" alt="Link tree" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCsHyV1sM2D2wxnoTKbyg2XA"
        target="_blank"
      >
        <img src="/yt.png" alt="Youtube" />
      </a>
      <a
        href="https://www.tiktok.com/@my.foodbuddy?_t=8eXSEloqHRp&_r=1"
        target="_blank"
      >
        <img src="/tiktok.png" alt="Tiktok" />
      </a>
      <a href="https://www.instagram.com/my.foodbuddy/" target="_blank">
        <img src="/insta.png" alt="Instagram" />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100094381201399#"
        target="_blank"
      >
        <img src="/fb.png" alt="Facebook" />
      </a>
      <!-- <a href="#" target="_blank">
        <img src="/lrb.png" alt="Little Red Book" />
      </a> -->
    </div>

    <h3><span class="dot">·</span> Contactez-moi</h3>
    <div>
      <a href="mailto:my.foodbuddy.pro@gmail.com">
        my.foodbuddy.pro@gmail.com
      </a>
    </div>


    <div class="made-with-love">
      Made with <span class="heart">&hearts;</span> in Paris
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
.app-footer {
  text-align: left;
  padding: 8px;
  font-size: 16px;
  color: #999;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  margin-top: 32px;
  padding-bottom: 16px;
  padding-left: 32px;

  .social {
    margin-top: 8px;
    a {
      display: inline-block;
      margin: 0 4px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  
  h3 {
    margin: 0;
    margin-top: 16px;
    margin-bottom: 8px;

    .dot {
      font-size: 1.6em;
      position: relative;
      top: 2px;
    }
  }

  a {
    color: #999;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .made-with-love {
    text-align: right;
    padding-top: 12px;
    padding-right: 12px;
  }

  .heart {
    color: #e25555;
    font-weight: bold;
    font-size: 1.5em;
    
    position: relative;
    top: 2px;
  }
}
</style>