<template>
  <div class="home">
    <div class="subtitles">🚩 adresses sur Paname et worldwide 🌍</div>
    <div class="social">
      <a
        href="https://www.youtube.com/channel/UCsHyV1sM2D2wxnoTKbyg2XA"
        target="_blank"
      >
        <img src="/yt.png" alt="Youtube" />
      </a>
      <a
        href="https://www.tiktok.com/@my.foodbuddy?_t=8eXSEloqHRp&_r=1"
        target="_blank"
      >
        <img src="/tiktok.png" alt="Tiktok" />
      </a>
      <a href="https://www.instagram.com/my.foodbuddy/" target="_blank">
        <img src="/insta.png" alt="Instagram" />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100094381201399#"
        target="_blank"
      >
        <img src="/fb.png" alt="Facebook" />
      </a>
      <!-- <a href="#" target="_blank">
        <img src="/lrb.png" alt="Little Red Book" />
      </a> -->
    </div>

    <div class="home-cnt">
      <h2>Newest</h2>
      <div class="articles-cnt">
        <ArticleItem :article="article" v-for="article in newArticles"
          :key="article.slug" />
      </div>

      <h2>Surprise me !</h2>
      <div class="articles-cnt random">
        <ArticleItem :article="article" v-for="article in randomArticles"
          :key="article.slug" />
      </div>
      <div class="un-autre">
        <button v-if="randomCount == 1" @click="newRandomArticles">Un autre !</button>
        <button v-if="randomCount == 3" @click="newRandomArticles">Trois autres !</button>
      </div>

      <div class="article-cta">
        <div class="cta-cnt">
          <router-link to="/articles">
            <button>Articles</button>
          </router-link>
          <router-link to="/about">
            <button>About me</button>
          </router-link>
        </div>
      </div>

      <tag-list/>

      <food-map />
    </div>
  </div>
</template>

<script>
import FoodMap from "@/components/FoodMap.vue";
import TagList from "@/components/TagList.vue";
import ArticleItem from "@/components/ArticleItem.vue";

export default {
  name: "HomeView",
  components: {
    FoodMap,
    TagList,
    ArticleItem,
  },
  data() {
    return {
      randomArticles: [],
    };
  },
  computed: {
    articles() {
      return this.$root.articles;
    },
    newArticles() {
      return this.articles.slice(-3).reverse();
    },
    randomCount() {
      return this.$root.isMobile ? 1 : 3;
    }
  },
  methods: {
    newRandomArticles() {
      const articles = JSON.parse(JSON.stringify(this.$root.articles));
      this.randomArticles = [];
      for (let i = 0; i < this.randomCount; i++) {
        const index = Math.floor(Math.random() * articles.length);
        this.randomArticles.push(articles.splice(index, 1)[0]);
      }
    },
  },
  mounted() {
    this.newRandomArticles();
  },
};
</script>

<style lang="scss">
.home {
  .subtitles {
    background-color: white;
    padding: 4px;
    width: 300px;
    margin: 0 auto 16px;
    border-radius: 16px;
  }

  .social {
    a {
      margin: 0 4px;

      img {
        width: 48px;
      }
    }
  }

  h2 {
    display: inline-block;
    background: #fff;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .article-cta {
    margin: 16px 0;
    text-align: left;

    .cta-cnt {
      width: 350px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      button {
        padding: 8px 40px;
        border-radius: 8px;
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        cursor: pointer;
        font-size: 1.2rem;
      }
    }
  }

  .articles-cnt {
    text-align: center;
    width: 100%;
    padding-left: 20px;

    .article-item {
      margin-bottom: 10px;
    }
  }

  .un-autre {
    margin: -10px 18px 16px;
    text-align: right;

    button {
      padding: 8px 40px;
      border-radius: 8px;
      background-color: #f8f8f8;
      border: 1px solid #ccc;
      cursor: pointer;
      font-size: 1.2rem;
    }
  }
}

@media (min-width: 900px) {
  .home {
    .articles-cnt {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin: 16px;
      padding: 0;
    }

    .un-autre {
      margin: 0;
    }
  }
}
</style>

