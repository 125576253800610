<template>
  <div class="tag-list">
    <div class="text-center" v-if="!noTitle">
      <h2>#tags</h2>
    </div>
    <router-link 
      class="tag-item" 
      v-for="tag in displayedTags" :key="tag.key" 
      :style="{
        color: tag.color || '#000000',
        backgroundColor: tag.background || '#f0f0f0'
      }"
      :to="`/${tag.key}`"
    >
      {{ tag.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    tags: Array,
    noTitle: Boolean,
  },
  computed: {
    allTags() {
      return Object.entries(this.$root.tags)
        .map(([key, tag]) => ({ ...tag, key }))
    },
    randomTags() {
      return this.allTags.slice().sort(() => Math.random() - 0.5)
        .slice(0, 20)
    },
    displayedTags() {
      return this.tags ? Object.fromEntries(
        this.tags.map(tag => [tag, {
          ...this.$root.tags[tag],
          key: tag,
        }])
      ) : this.randomTags
    },
  }
};
</script>

<style lang="scss">
.tag-list {
  padding: 0 12px 10px;

  .text-center  {
    text-align: center;

    h2 {
      display: inline-block;
      background: #fff;
      padding: 8px 16px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
  .tag-item {
    display: inline-block;
    margin: 0 5px 5px 0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s;
    font-family: serif;
    font-weight: 700;
  }
}
</style>