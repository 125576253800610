<template>
  <div class="menu-content">
    <router-link 
      class="menu-item" 
      v-for="tag in tags" :key="tag.key" 
      :to="`/${tag.key}`"
      :style="{ backgroundColor: tag.background, color: tag.color, 'font-size': tag.size + 'px', 'font-weight': tag.weight }"
    >
      {{ tag.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    tags() {
      return Object.entries(this.$root.tags)
        .map(([key, tag]) => ({ ...tag, key }))
        .filter(tag => tag.menu)
    }
  }
}
</script>

<style lang="scss">
.bm-item-list .menu-content {
  display: block;
  
  .menu-item {
    box-sizing: border-box;

    display: block;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    background: violet;
    color: white;

    height: 120px;
    width: 120px;
    border-radius: 50%;

    font-size: 40px;
    text-transform: capitalize;
    line-height: 120px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    
    
    margin: 8px 0;

    &:first-child {
      margin-top: -16px;
    }

    &:last-child {
      margin-bottom: 60px;
    }
  }
}
</style>