<template>
  <div id="app">
    <AppHeader />
    <div class="main-content">
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #c4dfe4;

  button, .button, .tag-item {
    font-family: serif;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  .social a {
    &:hover {
      opacity: 0.8;
    }
  }

  #app {
    position: relative;
    font-family: serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    .main-content {
      min-height: 90vh;
    }
  }
}

.desktop {
  display: none;
}

.bm-overlay {
  // background: rgb(236 96 196 / 30%)!important;
  background: #C4E0E4!important;
}

/* desktop */
@media (min-width: 900px) {
  .mobile {
    display: none;
  }
  
  body {
    #app {
      max-width: 1250px;
      margin: 0 auto;
    }
  }
}
</style>
