<template>
  <div class="app-header">
    <!-- <div class="menu-mobile">
      <div class="burger">
        <Slide right :cross-icon="true" :closeOnNavigation="true" width="180">
          <MenuContent />
        </Slide>
      </div>
    </div> -->

    <div class="app-header-cnt">
      <router-link to="/">
        <img src="/logo.png" class="logo" alt="Food Buddy"/>
      </router-link>
    </div>

    <!-- <div class="language-selector">
      <select class="language-selector__select" @change="changeLanguage" :value="locale">
        <option value="fr">FR</option>
        <option value="en">EN</option>
        <option value="cn">CN</option>
      </select>
    </div> -->
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'
import MenuContent from './MenuContent.vue'

export default {
  name: 'AppHeader',
  components: {
    Slide,
    MenuContent,
  },
  computed: {
    locale() {
      return this.$root.locale
    },
  },
  methods: {
    changeLanguage(e) {
      const language = e.target.value
      this.$root.setLocale(language)

      // reload
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
.app-header {
  .logo {
    width: 35%;
    padding: 16px;
  }

  .social {
    a {
      margin: 0 4px;

      img {
        width: 48px;
      }
    }
  }

  .bm-burger-button {
    left: 16px;
    top: 24px;

    .bm-burger-bars {
      background: #616161;
      border-radius: 20px;
    }
  }

  .bm-menu {
    // background: #C4E0E4;
    background: #ffcae7;
    color: black;

    .bm-cross-button {
      right: 10px!important;

      .bm-cross {
        background: black;
      }
    }
    
    .bm-item-list {
      color: black;

    }
  }

  .language-selector {
    position: absolute;
    right: 16px;
    top: 24px;

    &__select {
      background: transparent;
      border: none;
      color: #616161;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
      outline: none;
    }
  }
}

@media (min-width: 900px) {
  .app-header {
    .logo {
      width: 27%;
    }
  }
}
</style>