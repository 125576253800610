<template>
  <!-- <router-link class="article-item" :to="`/articles/${article.slug}`"> -->
  <div class="article-item">
    <div class="article-item-pic">
      <img :srcset="`/contrib/pics/${article.slug}_400.png 400w, /contrib/pics/${article.slug}_1200.png 1200w`" sizes="(max-width: 400px) 400px, 1200px" :alt="article.title"/>
    </div>

    <div class="article-item-details">
      <div class="article-item-title">{{ article.title }}</div>
      <div class="article-item-address">{{ article.address }}</div>
      <div class="article-item-city">{{ article.city }}, {{ article.country }}</div>
      <div class="article-item-cta">
        <router-link class="button" :to="`/articles/${article.slug}`">Lire l'article</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleItem',
  props: {
    article: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.article-item {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #ddd;
  width: calc(100% - 20px);
  max-width: calc(100vw - 20px);
  background: white;

  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .article-item-pic {
    width: 120px;
    height: 140px;
    margin-right: 16px;
    overflow: hidden;

    img {
      width: 100%;
      margin: 0 auto 16px;
    }
  }

  .article-item-details {
    width: calc(100% - 120px);
    text-align: left;
    padding-left: 10px;

    .article-item-title {
      font-size: 1.5em;
      font-weight: 500;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .article-item-cta {
      margin-top: 42px;
      text-align: right;

      a {
        padding: 8px 16px;
        border-radius: 5px;
        background-color: #f0f0f0;
        color: #333;
        text-decoration: none;
        transition: background-color 0.3s;
        font-family: serif;
        font-weight: 500;
      }
    }
  }
}

@media (min-width: 900px) {
  .article-item {
    max-width: 380px;
  }
}
</style>
