import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/articles',
    name: 'articleList',
    component: () => import(/* webpackChunkName: "articleList" */ '../views/ArticleListView.vue')
  },
  {
    path: '/articles/:slug',
    name: 'article',
    component: () => import(/* webpackChunkName: "article" */ '../views/ArticleView.vue')
  },
  {
    path: '/map',
    name: 'mapExample',
    component: () => import(/* webpackChunkName: "about" */ '../views/MapExampleView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:tag',
    name: 'tagArticleListView',
    component: () => import(/* webpackChunkName: "TagArticleListView" */ '../views/TagArticleListView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // Scroll to top when navigating to a new page
    return { x: 0, y: 0 }
  }
})

export default router
