import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import tags from '../public/contrib/tags.json'
import meta from '../public/contrib/meta.json'

// rm all tags with no articles
const tagsWithArticles = Object.fromEntries(
  Object.entries(tags).filter(([tag]) => meta.some(article => article.tags.includes(tag)))
)

new Vue({
  data() {
    // console.log({tags, meta})
    return {
      metaLoading: false,
      tags: tagsWithArticles,
      articles: meta.filter(a => !a.disabled),
      aboutArticle: meta.find(a => a.isAbout),

      locale: 'fr'
    }
  },
  methods: {
    setLocale(locale) {
      localStorage.setItem('locale', locale)
      this.locale = locale
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 900;
    },
  },
  router,
  render: h => h(App)
}).$mount('#app')
